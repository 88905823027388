<template>
<b-carousel v-if="data.Data.length > 1" v-model="value">
 <b-carousel-item v-for="(d, i) in data.Data" :key="i">
    <div
      :style="getDivStyles(d)"
      :class="getDivClasses(d)">
      <div class="flex-1 flex flex-col justify-center items-center text-center px-4">
        <h1 class="mb-4 font-bold text-2xl lg:text-5xl">{{d.Title}}</h1>
        <p v-if="d.SubtitleHTML" class="lg:w-3/4 lg:text-md" v-html="d.SubtitleHTML" />
        <p v-else class="lg:w-3/4 lg:text-md">{{d.Subtitle}}</p>
      </div>
    </div>
 </b-carousel-item>
</b-carousel>
<div
  v-else
    :style="getDivStyles(data.Data[0])"
    :class="getDivClasses(data.Data[0])">
    <div class="flex-1 flex flex-col justify-center items-center text-center px-4">
      <h1 class="mb-4 font-bold text-2xl lg:text-5xl">{{data.Data[0].Title}}</h1>
      <p v-if="data.Data[0].SubtitleHTML" class="lg:w-3/4 lg:text-md" v-html="data.Data[0].SubtitleHTML" />
      <p v-else class="lg:w-3/4 lg:text-md">{{data.Data[0].Subtitle}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data () {
    return {
      value: 0,
      desktopHeight: window.screen.height + 'px'
    }
  },
  methods: {
    getDivStyles (data) {
      if (!data) return null
      return {
        'background-image': `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('${data.Image}')`,
        height: (this.isSm || this.isMd) && !this.isLg ? '300px' : this.data.Height === 'full' ? this.desktopHeight : `calc(${this.desktopHeight}/2)`
      }
    },
    getDivClasses (data) {
      return ['w-full flex flex-col justify-center items-center bg-no-repeat bg-cover', data.Class]
    }
  }
}
</script>

<style>

</style>
